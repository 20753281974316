<template>
  <section>
    <div class="md:flex gap-12">
      <div class="w-full lg:w-6/12">
        <div v-for="(nivel1, i) of listadoServicios[0]" :key="i" class="w-full">
          <div class="p-2 flex items-center justify-between border-b-2 border-gray-600">
            <button @click="nivel1.open = !nivel1.open" class="flex gap-2 items-center text-gray-800">
              <div v-if="nivel1.per.length">
                <svg v-if="nivel1.open" style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="gray" d="M7,10L12,15L17,10H7Z" />
                </svg>
                <svg v-else style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="gray" d="M10,17L15,12L10,7V17Z" />
                </svg>
              </div>
              <p class="text-gray-600 font-bold">
                {{ nivel1.name }}
              </p>
            </button>
            <div>
              <p :class="nivel1.autorizacion === 1 ? 'text-blue-600' : (nivel1.autorizacion === 2 ? 'text-blue-300' : 'text-gray-400')">
                {{
                  nivel1.per.length ?
                  (nivel1.per.some(x => x.creacion_edicion) ? permisosLimitados.filter(x => x.id === nivel1.autorizacion)[0].name :
                  listadoTiposPermisosConHijos.filter(x => x.id === nivel1.autorizacion)[0].name) :
                  listadoTiposPermisos.filter(x => x.id === nivel1.autorizacion)[0].name
                }}
              </p>
            </div>
          </div>
          <div v-show="nivel1.open && nivel1.per.length">
            <div v-if="nivel1.per.some(a => a.creacion_edicion === false)">
              <div v-for="(nivel2, i) of nivel1.per" :key="i" class="w-full pl-4">
                <div class="p-2 flex items-center justify-between border-b">
                  <button @click="nivel2.open = !nivel2.open" class="flex items-center text-gray-800">
                    <div class="mr-2" v-if="nivel2.per.length">
                      <div v-if="nivel2.per.some(a => a.creacion_edicion === false)">
                        <svg v-if="nivel2.open" style="width:24px;height:24px" viewBox="0 0 24 24">
                            <path fill="gray" d="M7,10L12,15L17,10H7Z" />
                        </svg>
                        <svg v-else style="width:24px;height:24px" viewBox="0 0 24 24">
                            <path fill="gray" d="M10,17L15,12L10,7V17Z" />
                        </svg>
                      </div>
                    </div>
                    <p class="text-gray-600">
                      {{ nivel2.name }}
                    </p>
                  </button>
                  <div>
                    <p :class="nivel2.autorizacion === 1 ? 'text-blue-600' : (nivel2.autorizacion === 2 ? 'text-blue-300' : 'text-gray-400')">
                      {{
                        nivel2.per.length ?
                        (nivel2.per.some(x => x.creacion_edicion) ? permisosLimitados.filter(x => x.id === nivel2.autorizacion)[0].name :
                        listadoTiposPermisosConHijos.filter(x => x.id === nivel2.autorizacion)[0].name) :
                        listadoTiposPermisos.filter(x => x.id === nivel2.autorizacion)[0].name
                      }}
                    </p>
                  </div>
                </div>
                <div v-show="nivel2.open && nivel2.per.length">
                  <div v-if="nivel2.per.some(a => a.creacion_edicion === false)">
                    <div v-for="(nivel3, i) of nivel2.per" :key="i" class="w-full pl-4">
                      <div class="p-2 flex items-center justify-between border-b">
                        <button @click="nivel3.open = !nivel3.open" class="flex items-center text-gray-800">
                          <div v-if="nivel3.per.length">
                            <div class="mr-2" v-if="nivel3.per.some(a => a.creacion_edicion === false)">
                              <svg v-if="nivel3.open" style="width:24px;height:24px" viewBox="0 0 24 24">
                                  <path fill="gray" d="M7,10L12,15L17,10H7Z" />
                              </svg>
                              <svg v-else style="width:24px;height:24px" viewBox="0 0 24 24">
                                  <path fill="gray" d="M10,17L15,12L10,7V17Z" />
                              </svg>
                            </div>
                          </div>
                          <p class="text-gray-600">
                            {{ nivel3.name }}
                          </p>
                        </button>
                        <div>
                          <p :class="nivel3.autorizacion === 1 ? 'text-blue-600' : (nivel3.autorizacion === 2 ? 'text-blue-300' : 'text-gray-400')">
                            {{
                              nivel3.per.length ?
                              (nivel3.per.some(x => x.creacion_edicion) ? permisosLimitados.filter(x => x.id === nivel3.autorizacion)[0].name :
                              listadoTiposPermisosConHijos.filter(x => x.id === nivel3.autorizacion)[0].name) :
                              listadoTiposPermisos.filter(x => x.id === nivel3.autorizacion)[0].name
                            }}
                          </p>
                        </div>
                      </div>
                      <div v-show="nivel3.open && nivel3.per.length">
                        <div v-if="nivel3.per.some(a => a.creacion_edicion === false)">
                          <div v-for="(nivel4, i) of nivel3.per" :key="i" class="w-full pl-4">
                            <div class="p-2 flex items-center justify-between border-b">
                              <button @click="nivel4.open = !nivel4.open" class="flex items-center text-gray-800">
                                <div v-if="nivel4.per.length">
                                  <div class="mr-2" v-if="nivel4.per.some(a => a.creacion_edicion === false)">
                                    <svg v-if="nivel4.open" style="width:24px;height:24px" viewBox="0 0 24 24">
                                        <path fill="gray" d="M7,10L12,15L17,10H7Z" />
                                    </svg>
                                    <svg v-else style="width:24px;height:24px" viewBox="0 0 24 24">
                                        <path fill="gray" d="M10,17L15,12L10,7V17Z" />
                                    </svg>
                                  </div>
                                </div>
                                <p class="text-gray-600">
                                  {{ nivel4.name }}
                                </p>
                              </button>
                              <div>
                                <p :class="nivel4.autorizacion === 1 ? 'text-blue-600' : (nivel4.autorizacion === 2 ? 'text-blue-300' : 'text-gray-400')">
                                  {{
                                    nivel4.per.length ?
                                    (nivel4.per.some(x => x.creacion_edicion) ? permisosLimitados.filter(x => x.id === nivel4.autorizacion)[0].name :
                                    listadoTiposPermisosConHijos.filter(x => x.id === nivel4.autorizacion)[0].name) :
                                    listadoTiposPermisos.filter(x => x.id === nivel4.autorizacion)[0].name
                                  }}
                                </p>
                              </div>
                            </div>
                            <div v-show="nivel4.open && nivel4.per.length">
                              <div v-if="nivel4.per.some(a => a.creacion_edicion === false)">
                                <div v-for="(nivel5, i) of nivel4.per" :key="i" class="w-full pl-4">
                                  <div class="p-2 flex items-center justify-between border-b">
                                    <button @click="nivel5.open = !nivel5.open" class="flex items-center text-gray-800">
                                      <div v-if="nivel5.per">
                                        <div v-show="nivel5.per.length">
                                          <div class="mr-2" v-if="nivel5.per.some(a => a.creacion_edicion === false)">
                                            <svg v-if="nivel5.open" style="width:24px;height:24px" viewBox="0 0 24 24">
                                                <path fill="gray" d="M7,10L12,15L17,10H7Z" />
                                            </svg>
                                            <svg v-else style="width:24px;height:24px" viewBox="0 0 24 24">
                                                <path fill="gray" d="M10,17L15,12L10,7V17Z" />
                                            </svg>
                                          </div>
                                        </div>
                                      </div>
                                      <p class="text-gray-600">
                                        {{ nivel5.name }}
                                      </p>
                                    </button>
                                    <div>
                                      <p :class="nivel5.autorizacion === 1 ? 'text-blue-600' : (nivel5.autorizacion === 2 ? 'text-blue-300' : 'text-gray-400')">
                                        {{
                                          nivel5.per.length ?
                                          (nivel5.per.some(x => x.creacion_edicion) ? permisosLimitados.filter(x => x.id === nivel5.autorizacion)[0].name :
                                          listadoTiposPermisosConHijos.filter(x => x.id === nivel5.autorizacion)[0].name) :
                                          listadoTiposPermisos.filter(x => x.id === nivel5.autorizacion)[0].name
                                        }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full lg:w-6/12">
        <div v-for="(nivel1, i) of listadoServicios[1]" :key="i" class="w-full">
          <div class="p-2 flex items-center justify-between border-b-2 border-gray-600">
            <button @click="nivel1.open = !nivel1.open" class="flex gap-2 items-center text-gray-800">
              <div v-if="nivel1.per.length">
                <svg v-if="nivel1.open" style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="gray" d="M7,10L12,15L17,10H7Z" />
                </svg>
                <svg v-else style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="gray" d="M10,17L15,12L10,7V17Z" />
                </svg>
              </div>
              <p class="text-gray-600 font-bold">
                {{ nivel1.name }}
              </p>
            </button>
            <div>
              <p :class="nivel1.autorizacion === 1 ? 'text-blue-600' : (nivel1.autorizacion === 2 ? 'text-blue-300' : 'text-gray-400')">
                {{
                  nivel1.per.length ?
                  (nivel1.per.some(x => x.creacion_edicion) ? permisosLimitados.filter(x => x.id === nivel1.autorizacion)[0].name :
                  listadoTiposPermisosConHijos.filter(x => x.id === nivel1.autorizacion)[0].name) :
                  listadoTiposPermisos.filter(x => x.id === nivel1.autorizacion)[0].name
                }}
              </p>
            </div>
          </div>
          <div v-show="nivel1.open && nivel1.per.length">
            <div v-if="nivel1.per.some(a => a.creacion_edicion === false)">
              <div v-for="(nivel2, i) of nivel1.per" :key="i" class="w-full pl-4">
                <div class="p-2 flex items-center justify-between border-b">
                  <button @click="nivel2.open = !nivel2.open" class="flex items-center text-gray-800">
                    <div class="mr-2" v-if="nivel2.per.length">
                      <div v-if="nivel2.per.some(a => a.creacion_edicion === false)">
                        <svg v-if="nivel2.open" style="width:24px;height:24px" viewBox="0 0 24 24">
                            <path fill="gray" d="M7,10L12,15L17,10H7Z" />
                        </svg>
                        <svg v-else style="width:24px;height:24px" viewBox="0 0 24 24">
                            <path fill="gray" d="M10,17L15,12L10,7V17Z" />
                        </svg>
                      </div>
                    </div>
                    <p class="text-gray-600">
                      {{ nivel2.name }}
                    </p>
                  </button>
                  <div>
                    <p :class="nivel2.autorizacion === 1 ? 'text-blue-600' : (nivel2.autorizacion === 2 ? 'text-blue-300' : 'text-gray-400')">
                      {{
                        nivel2.per.length ?
                        (nivel2.per.some(x => x.creacion_edicion) ? permisosLimitados.filter(x => x.id === nivel2.autorizacion)[0].name :
                        listadoTiposPermisosConHijos.filter(x => x.id === nivel2.autorizacion)[0].name) :
                        listadoTiposPermisos.filter(x => x.id === nivel2.autorizacion)[0].name
                      }}
                    </p>
                  </div>
                </div>
                <div v-show="nivel2.open && nivel2.per.length">
                  <div v-if="nivel2.per.some(a => a.creacion_edicion === false)">
                    <div v-for="(nivel3, i) of nivel2.per" :key="i" class="w-full pl-4">
                      <div class="p-2 flex items-center justify-between border-b">
                        <button @click="nivel3.open = !nivel3.open" class="flex items-center text-gray-800">
                          <div v-if="nivel3.per.length">
                            <div class="mr-2" v-if="nivel3.per.some(a => a.creacion_edicion === false)">
                              <svg v-if="nivel3.open" style="width:24px;height:24px" viewBox="0 0 24 24">
                                  <path fill="gray" d="M7,10L12,15L17,10H7Z" />
                              </svg>
                              <svg v-else style="width:24px;height:24px" viewBox="0 0 24 24">
                                  <path fill="gray" d="M10,17L15,12L10,7V17Z" />
                              </svg>
                            </div>
                          </div>
                          <p class="text-gray-600">
                            {{ nivel3.name }}
                          </p>
                        </button>
                        <div>
                          <p :class="nivel3.autorizacion === 1 ? 'text-blue-600' : (nivel3.autorizacion === 2 ? 'text-blue-300' : 'text-gray-400')">
                            {{
                              nivel3.per.length ?
                              (nivel3.per.some(x => x.creacion_edicion) ? permisosLimitados.filter(x => x.id === nivel3.autorizacion)[0].name :
                              listadoTiposPermisosConHijos.filter(x => x.id === nivel3.autorizacion)[0].name) :
                              listadoTiposPermisos.filter(x => x.id === nivel3.autorizacion)[0].name
                            }}
                          </p>
                        </div>
                      </div>
                      <div v-show="nivel3.open && nivel3.per.length">
                        <div v-if="nivel3.per.some(a => a.creacion_edicion === false)">
                          <div v-for="(nivel4, i) of nivel3.per" :key="i" class="w-full pl-4">
                            <div class="p-2 flex items-center justify-between border-b">
                              <button @click="nivel4.open = !nivel4.open" class="flex items-center text-gray-800">
                                <div v-if="nivel4.per.length">
                                  <div class="mr-2" v-if="nivel4.per.some(a => a.creacion_edicion === false)">
                                    <svg v-if="nivel4.open" style="width:24px;height:24px" viewBox="0 0 24 24">
                                        <path fill="gray" d="M7,10L12,15L17,10H7Z" />
                                    </svg>
                                    <svg v-else style="width:24px;height:24px" viewBox="0 0 24 24">
                                        <path fill="gray" d="M10,17L15,12L10,7V17Z" />
                                    </svg>
                                  </div>
                                </div>
                                <p class="text-gray-600">
                                  {{ nivel4.name }}
                                </p>
                              </button>
                              <div>
                                <p :class="nivel4.autorizacion === 1 ? 'text-blue-600' : (nivel4.autorizacion === 2 ? 'text-blue-300' : 'text-gray-400')">
                                  {{
                                    nivel4.per.length ?
                                    (nivel4.per.some(x => x.creacion_edicion) ? permisosLimitados.filter(x => x.id === nivel4.autorizacion)[0].name :
                                    listadoTiposPermisosConHijos.filter(x => x.id === nivel4.autorizacion)[0].name) :
                                    listadoTiposPermisos.filter(x => x.id === nivel4.autorizacion)[0].name
                                  }}
                                </p>
                              </div>
                            </div>
                            <div v-show="nivel4.open && nivel4.per.length">
                              <div v-if="nivel4.per.some(a => a.creacion_edicion === false)">
                                <div v-for="(nivel5, i) of nivel4.per" :key="i" class="w-full pl-4">
                                  <div class="p-2 flex items-center justify-between border-b">
                                    <button @click="nivel5.open = !nivel5.open" class="flex items-center text-gray-800">
                                      <div v-if="nivel5.per">
                                        <div v-show="nivel5.per.length">
                                          <div class="mr-2" v-if="nivel5.per.some(a => a.creacion_edicion === false)">
                                            <svg v-if="nivel5.open" style="width:24px;height:24px" viewBox="0 0 24 24">
                                                <path fill="gray" d="M7,10L12,15L17,10H7Z" />
                                            </svg>
                                            <svg v-else style="width:24px;height:24px" viewBox="0 0 24 24">
                                                <path fill="gray" d="M10,17L15,12L10,7V17Z" />
                                            </svg>
                                          </div>
                                        </div>
                                      </div>
                                      <p class="text-gray-600">
                                        {{ nivel5.name }}
                                      </p>
                                    </button>
                                    <div>
                                      <p :class="nivel5.autorizacion === 1 ? 'text-blue-600' : (nivel5.autorizacion === 2 ? 'text-blue-300' : 'text-gray-400')">
                                        {{
                                          nivel5.per.length ?
                                          (nivel5.per.some(x => x.creacion_edicion) ? permisosLimitados.filter(x => x.id === nivel5.autorizacion)[0].name :
                                          listadoTiposPermisosConHijos.filter(x => x.id === nivel5.autorizacion)[0].name) :
                                          listadoTiposPermisos.filter(x => x.id === nivel5.autorizacion)[0].name
                                        }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { ref, computed } from 'vue'
import permisosStore from '../../../../../../../stores/permisos.store'

export default {
  name: 'permisos',
  setup () {
    // Manual Data
    const listadoTiposPermisosConHijos = ref([
      {
        name: 'Autorización total',
        id: 1,
        class: 'bg-gray-100 text-blue-600'
      },
      {
        name: 'Varias autorizaciones',
        id: 2,
        class: 'bg-gray-100 text-gray-800'
      },
      {
        name: 'Falta autorización',
        id: 3,
        class: 'bg-gray-100 text-gray-400'
      }
    ])
    const listadoTiposPermisos = ref([
      {
        name: 'Autorización total',
        id: 1,
        class: 'bg-gray-100 text-blue-600'
      },
      {
        name: 'Falta autorización',
        id: 3,
        class: 'bg-gray-100 text-gray-400'
      }
    ])
    const permisosLimitados = ref([
      {
        name: 'Autorización total',
        id: 1,
        class: 'bg-gray-100 text-blue-600'
      },
      {
        name: 'Solo lectura',
        id: 2,
        class: 'bg-gray-100 text-gray-800'
      },
      {
        name: 'Falta autorización',
        id: 3,
        class: 'bg-gray-100 text-gray-400'
      }
    ])
    // Computed
    const listadoServicios = computed(() => permisosStore.getters._listadoServicios)
    // Data

    return {
      listadoTiposPermisosConHijos,
      listadoTiposPermisos,
      permisosLimitados,
      listadoServicios
    }
  }
}
</script>

<style lang="scss" scoped>
  ::-webkit-scrollbar {
    width: 0.2rem;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(248, 248, 248);
    border-radius: 9999px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgb(207, 207, 207);
  }
  .selectButtonPermissions {
    ::v-deep(.p-checkbox .p-checkbox-box) {
      border-radius: 50%;
    }
    ::v-deep(.p-selectbutton .p-button.p-highlight){
      background: white;
      color: #1C3FAA;
      width: 100%;
      font-weight: 500;
      font-size: 1rem;
      border: 1px solid rgb(28, 63, 170);
      margin-top: 0.5rem;
   }
   ::v-deep(.p-selectbutton .p-button.p-highlight:hover){
      background: white;
      color: #1C3FAA;
      width: 100%;
      font-weight: 500;
      font-size: 1rem;
      border: 1px solid rgb(28, 63, 170);
      margin-top: 0.5rem;
   }
   ::v-deep(.p-selectbutton .p-button){
      background: #F6F6F6;
      color: #7F7E81;
      width: 100%;
      font-size: 1rem;
      margin-top: 0.5rem;
      border: transparent;
    }
   ::v-deep(.p-selectbutton .p-button:hover){
      background: #F6F6F6;
      color: #7F7E81;
      width: 100%;
      font-size: 1rem;
      margin-top: 0.5rem;
      border: transparent;
   }
   ::v-deep(.p-dropdown .p-dropdown-label){
      font-size: 0.4rem !important;
      padding: 0.2rem !important;
   }
  }
</style>
